import { t } from "i18next";
import { SummaryComponent } from "../../../ui/components/SummaryComponent";
import "./SmartPlanDetails.css";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import { BookNow } from "../../../ui/components/ComponentBookNow";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import {
  IncludedService,
  getSmartPlanIncludedServiceAsync,
  includedServiceSelector,
} from "../../IncludedServices/IncludedServiceSlice";
import { useAppSelector } from "../../../app/hooks";
import { useEffect, useRef, useState } from "react";

import {
  SmartPlan,
  getSmartPlanAsync,
  smartPlanSelector,
  createSmartPlanInfoAsync
} from "../SmartPlanSlice";
import {
  Activity,
  activitySelector,
  getSmartPlanActivityAsync,
} from "../../Activity/ActivitySlice";
import { Faq, faqSelector, getSmartPlanFaqsAsync } from "../../Faq/FaqSlice";
import {
  SmartDatePlan,
  getSmartDatePlanAsync,
  smartDatePlanSelector,
} from "../SmartDatePlan/SmartDatePlanSlice";
import { useMatch, useNavigate } from "react-router-dom";
import { RootState, store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { Footer } from "../../../screens/Footer/Footer";
import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { Map } from "../../../ui/components/Map/Map";
import { TwentyIcArrowLeft } from "../../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowRight } from "../../../ui/components/icons/TwentyIcArrowRight";
import {
  Gallery,
  gallerySelector,
  getSmartPlanGalleyAsync,
} from "../../Gallery/GallerySlice";
import { SmartDatePlanModal } from "../SmartDatePlan/SmartDatePlanModal/SmartDatePlanModal";
import _ from "lodash";

import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { Link } from "react-router-dom";
import {
  CheckoutItem,
  MyOrder,
  createCheckoutSessionAsync,
  createOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import { formatText } from "../../../utils/utils";
import { Button, Input, Modal } from "antd";
import axios from "axios";

export const SmartPlanDetails = () => {
  const [id, setId] = useState<number>(-1);
  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);
  const [, setIsBookingLoading] = useState(false);

  const smartPlanDetailsRoute = useMatch("smartPlan/:id/details");

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  let smartPlan: SmartPlan = useAppSelector(
    (state) => smartPlanSelector.selectById(state, id) ?? ({} as SmartPlan)
  );

  let includedService: IncludedService[] = useAppSelector(
    includedServiceSelector.selectAll
  );

  let activity: Activity[] = useAppSelector(activitySelector.selectAll);

  let faq: Faq[] = useAppSelector(faqSelector.selectAll);

  let smartDatePlan: SmartDatePlan[] = useAppSelector(
    smartDatePlanSelector.selectAll
  );

  let bookNowModalObj: SmartDatePlan = useAppSelector(
    (state) =>
      smartDatePlanSelector.selectById(state, bookNowModalId) ??
      ({} as SmartDatePlan)
  );

  let gallery: Gallery[] = useAppSelector(gallerySelector.selectAll);

  const imageList = gallery.map((el) => el.image);

  const myOrderSliceStatus = useAppSelector(selectMyOrdersSliceStatus);

  const handleClick = () => {
    const elementToScrollTo = document.getElementById("orderSection");
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({ behavior: "smooth" });
    }
  };

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");
  const contactLabelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (isGalleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? imageList.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === imageList.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isGalleryOpen, imageList, currentImageIndex]);

  function convertDayDate(dateStr: string) {
    const inputDate: Date = new Date(dateStr);
    const day: number = inputDate.getDate();
    return day.toString();
  }

  const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatData = (dataString: string): string => {
    const data = parseISO(dataString);
    const formatoData = "EEE dd MMM";
    const dataFormattata = format(data, formatoData, { locale: it });

    const word = dataFormattata.split(" ");
    const dataFormattataConMaiuscole = word
      .map((word) => capitalize(word))
      .join(" ");

    return dataFormattataConMaiuscole;
  };

  const redirectToStripe = (stripeCheckoutURL: string) => {
    if (stripeCheckoutURL) {
      window.location.href = stripeCheckoutURL;
    }
  };

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);

  const createActionOnClick = (numPerson: number) => {
    const userInfo = localStorage.getItem("customerId");
    const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
    const tenant = localStorage.getItem("tenantId");
    const tenantId = tenant ? parseInt(tenant, 10) : 0;
    const orderData: MyOrder = {
      id: 0,
      tenantId: tenantId,
      bookingDate: new Date(hoursItalian),
      numberOfPeople: numPerson,
      status: 1,
      purchasedBy: userInfoId,
      smartPlanId: id,
      smartPlanDateId: bookNowModalId,
      profilePhoto: "",
    };
    store
      .dispatch(createOrderAsync({ myOrder: orderData }))
      .then((result: any) => {
        if (
          result.meta.requestStatus === "fulfilled" &&
          process.env.REACT_APP_PUBLIC_URL
        ) {
          const bookingId = result.payload.id;
          const checkoutItem: CheckoutItem = {
            successURL:
              process.env.REACT_APP_PUBLIC_URL +
              "smartplan/" +
              id +
              "/success/" +
              result.payload.id,
            cancelURL:
              process.env.REACT_APP_PUBLIC_URL + "smartplan/" + id + "/details",
          };
          store
            .dispatch(createCheckoutSessionAsync({ checkoutItem, bookingId }))
            .then((stripeResult: any) => {
              redirectToStripe(stripeResult.payload);
            });
        }
      })
      .catch(() => {
        ToastNotification({
          status: "error",
          description: "",
          toastId: "Booking not created",
          title: t("toastNotification.networkError"),
        });
      })
      .finally(() => {
        setIsBookingLoading(false);
      });
    setIsBookingLoading(true);
  };

  useEffect(() => {
    if (smartPlanDetailsRoute !== null) {
      if (smartPlanDetailsRoute.params.id) {
        setId(parseInt(smartPlanDetailsRoute.params.id));
        store.dispatch(
          getSmartPlanAsync(parseInt(smartPlanDetailsRoute.params.id))
        );
        store.dispatch(
          getSmartPlanActivityAsync({
            smartPlanId: parseInt(smartPlanDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getSmartPlanFaqsAsync({
            smartPlanId: parseInt(smartPlanDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getSmartDatePlanAsync({
            smartPlanId: parseInt(smartPlanDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getSmartPlanIncludedServiceAsync({
            smartPlanId: parseInt(smartPlanDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getSmartPlanGalleyAsync({
            smartPlanId: parseInt(smartPlanDetailsRoute.params.id),
          })
        );
        return;
      }
    }
  }, [smartPlanDetailsRoute]);

  const navigate = useNavigate();
  const defaultValuePerson = 1;

  const isLoading = myOrderSliceStatus === "loading";

  const userInfo = useAppSelector((state: RootState) => state.userInfo);

  const userId = userInfo.ids[0];

  const user = userId ? userInfo.entities[userId] : null;

  const [numberOfPeople, setNumberOfPeople] = useState<number>(1);

  const [requestMessage, setRequestMessage] = useState<string>("");

  const handleSubmit = () => {
    if (!user || !user.id || isNaN(user.id)) {
      console.error("User ID non valido:", user ? user.id : "null");
      Modal.error({ content: "Errore: ID utente non valido." });
      return;
    }

    const userId = user.id;

    if (!smartPlan || !smartPlan.id) {
      console.error(
        "smartPlan.id non valido:",
        smartPlan ? smartPlan.id : "null"
      );
      Modal.error({ content: "Errore: ID della smartPlan non valido." });
      return;
    }

    console.log("Dati utente:", user);
    console.log("smartPlan:", smartPlan);

    const customerName =
      user?.firstname && user?.lastname
        ? `${user.firstname} ${user.lastname}`
        : "Nome non disponibile";

    const customerPhone = user?.telephone || "Telefono non disponibile";

    const companyName = user?.tenant.businessName || "Azienda non disponibile";

    const requestData = {
      smartplanId: smartPlan.id,
      customerName: customerName,
      customerEmail: user.email,
      customerPhone: customerPhone,
      companyName: companyName,
      serviceName: smartPlan.previewDescription,
      personNumber: numberOfPeople,
      requestMessage: requestMessage,
    };


    if (!user || !user.id) {
      console.error("User ID non valido:", user.id);
      Modal.error({ content: "Errore: ID utente non valido." });
      return;
    }

    const tenant = localStorage.getItem("tenantId");

    const tenantId = tenant ? parseInt(tenant, 10) : 0;

   

   store
        .dispatch(createSmartPlanInfoAsync({smartPlanInfoDTO : requestData}))
        .then(() => {
          Modal.success({ content: "Richiesta inviata con successo!" });
        })
        .catch((error) => {
          console.error("Errore nell'invio della richiesta:", error);
          Modal.error({ content: "Errore nell'invio della richiesta. Riprova." });
        });
  };
  return (
    <>
      <HeaderMenu>
        <TopBar
          title={smartPlan && smartPlan.city + " • " + smartPlan.region}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            smartPlan &&
            "SmartPlan > " + smartPlan.city + " • " + smartPlan.region
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/smartPlan")}
        />
      </HeaderMenu>
      <div className="smartplandetail-container">
        <img
          className="rectangle-8"
          alt=""
          src={(smartPlan && smartPlan.image) ?? undefined}
        />
        <div className="overlap-2">
          <div className="smartplandetail-bady-page">
            {/* region Services */}
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>
            <div className="service-container" style={{ flexWrap: "wrap" }}>
              {includedService.map((el) => (
                <div>
                  <div
                    className="service-title"
                    style={{ whiteSpace: "normal" }}
                  >
                    {el.title}
                  </div>
                  <div className="service-subtitle">{el.description}</div>
                </div>
              ))}
            </div>
            {/* endregion Services */}
            {/* region Gallery */}
            <div className="title-gallery">{t("smartPlanDetail.gallery")}</div>
            <div className="smart-plan">
              {" "}
              <div className="flex-container-gallery">
                {gallery &&
                  gallery.map((item, index) => (
                    <div className="gallery-container" key={item.id}>
                      <img
                        className="gallery-image"
                        src={item.image}
                        aria-hidden
                        alt={`Image ${item.id}`}
                        onClick={() => openGallery(index)}
                      />
                    </div>
                  ))}
              </div>
              {isGalleryOpen && (
                <div className="gallery-overlay">
                  <button className="close-button" onClick={closeGallery}>
                    x
                  </button>
                  <button
                    className="prev-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === 0 ? imageList.length - 1 : prev - 1
                      )
                    }
                  >
                    <TwentyIcArrowLeft className="arrow-gallery" />
                  </button>
                  <button
                    className="next-button"
                    onClick={() =>
                      setSelectedImageIndex((prev) =>
                        prev === imageList.length - 1 ? 0 : prev + 1
                      )
                    }
                  >
                    <TwentyIcArrowRight
                      className="arrow-gallery"
                      onClick={undefined}
                    />
                  </button>
                  <div className="gallery">
                    <img
                      src={imageList[selectedImageIndex]}
                      alt="img"
                      className="gallery-image-large"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* endregion Gallery */}
            {/* region Description */}
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <div className="description-smartpaln">
              {smartPlan && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      smartPlan.description?.replace(/\s+/g, " ").trim() ?? "",
                  }}
                />
              )}
            </div>
            <div className="text-wrapper-21-map">
              {t("smartPlanDetail.map")}
            </div>
            {/* endregion Description */}
            {/* regin Map */}
            <Map city={smartPlan && smartPlan.city} />
            {/* endregion Map */}
            {/* regin Activity */}
            <div className="text-wrapper-21-activity">
              {t("smartPlanDetail.activityAndRoutes.activityAndRoutes")}
            </div>
            <p className="text-wrapper-26-activity-desc">
              {t("smartPlanDetail.activityAndRoutes.activityAndRoutesDesc")}
            </p>
            <div className="flex-container-activity">
              {activity &&
                activity.map((el) => (
                  <div className="activity-container">
                    <img
                      className="activity-image"
                      src={el.imageUrl}
                      alt="Img"
                    />
                    <div className="activity-title">{el.title}</div>
                    <div
                      className="service-title"
                      style={{ whiteSpace: "normal" }}
                    >
                      {" "}
                      {t("smartPlan.common.objective")}
                    </div>
                    <div className="activity-subtitle">{el.subtitle}</div>
                  </div>
                ))}
            </div>
            {/* endregin Activity */}
            {/* regin Booking */}
            <div className="smartPlanDetail-bookNow">
              <div className="frame-44">
                <h3>Contattaci</h3>
                <p>Parliamone per migliorare l'esperienza e definire le date</p>
              </div>
              <div className="contatto-smartplan">
                <label className="contatto-smartplan--label">
                  N. di persone
                </label>
                <Input
                  type="number"
                  min="1"
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(Number(e.target.value))}
                />
                <label
                  ref={contactLabelRef}
                  className="contatto-smartplan--label"
                >
                  Scrivi la tua richiesta
                </label>
                <Input.TextArea
                  rows={8}
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                ></Input.TextArea>
                <Button
                  className="contatto-smartplan--submit"
                  onClick={handleSubmit}
                >
                  Invia richiesta
                </Button>
              </div>

              {/* <div className="flex-container-bookNow" id="orderSection">
                <div className="line-bookNow-title-smartplan" />
                {smartDatePlan &&
                  smartDatePlan.map((el) => (
                    <BookNow
                      label={
                        <div className="bookNow-deposit">
                          {t("smartPlanDetail.bookNow.bookNow")} <br />
                          {t("smartPlanDetail.bookNow.deposit")}
                          {el.deposit}€
                        </div>
                      }
                      price={el.price}
                      month={el.month}
                      startDay={convertDayDate(el.startDay)}
                      endDay={convertDayDate(el.endDay)}
                      onClick={() => {
                        setBookNowModalId(el.id);
                      }}
                    />
                  ))}
              </div>
              */}

              {!_.isEmpty(bookNowModalObj) && (
                <SmartDatePlanModal
                  onClose={() => {
                    navigate("/smartPlan/" + smartPlan.id + "/details");
                    setBookNowModalId(-1);
                  }}
                  title={t("smartPlanDetail.bookNow.bookNowModal.title")}
                  month={bookNowModalObj.month}
                  startDay={convertDayDate(bookNowModalObj.startDay)}
                  endDay={convertDayDate(bookNowModalObj.endDay)}
                  deposit={bookNowModalObj.deposit}
                  price={bookNowModalObj.price}
                  onClick={(npersone) =>
                    createActionOnClick(npersone ?? defaultValuePerson)
                  }
                  isActionPerforming={isLoading}
                />
              )}
            </div>
            {/* endregin Booking */}
            {/* regin Faq */}
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t("smartPlanDetail.questionsAndAnswers.questionsAndAnswers")}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer-smartplan">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div className="answer-smartplan">
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
            {/* endregin Faq */}
          </div>{" "}
          <div className="container-summary">
            <div className="summary-wrapper">
              <SummaryComponent
                province=""
                goToBooks={handleClick}
                region={smartPlan.region ? smartPlan.region : " -- "}
                selectDate={t("smartPlanDetail.componentSummary.selectDate")}
                startDate={
                  smartPlan.startDate
                    ? formatData(smartPlan.startDate.toString())
                    : "Da definire"
                }
                endDate={
                  smartPlan.endDate
                    ? formatData(smartPlan.endDate.toString())
                    : "Da definire"
                }
                destination={smartPlan.city ? smartPlan.city : "--"}
                activity={
                  activity.length +
                  t("smartPlanDetail.componentSummary.intensiveRoutes")
                }
                price={smartPlan.price ? smartPlan.price : 0}
                destinationText={t(
                  "smartPlanDetail.componentSummary.destination"
                )}
                activityText={t("smartPlanDetail.componentSummary.activity")}
                summary={t("smartPlanDetail.componentSummary.summary")}
                start={t("smartPlanDetail.componentSummary.start")}
                end={t("smartPlanDetail.componentSummary.end")}
                startingFrom={t(
                  "smartPlanDetail.componentSummary.startingFrom"
                )}
                downPayment={t("smartPlanDetail.componentSummary.downPayment")}
                descriptionP1={t(
                  "smartPlanDetail.componentSummary.descriptionP1"
                )}
                descriptionP2={t(
                  "smartPlanDetail.componentSummary.descriptionP2"
                )}
                onClick={() => {
                  if (contactLabelRef.current) {
                    contactLabelRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                }}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "smartPlanDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                date={t("smartPlanDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", firstName ?? "")
                  .replace("lastName*", lastName ?? "")
                  .replace("companyName*", businessName ?? "")}
                disabled={undefined}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
