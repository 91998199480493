import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";
import { t } from "i18next";
import {
  useEffect,
  useState,
  useRef,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
} from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState, store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import { Map } from "../../../ui/components/Map/Map";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import { SummaryComponent } from "../../../ui/components/SummaryComponent";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLeft } from "../../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcArrowRight } from "../../../ui/components/icons/TwentyIcArrowRight";
import { Faq, faqSelector, getMasterClassFaqsAsync } from "../../Faq/FaqSlice";
import {
  Gallery,
  gallerySelector,
  getMasterClassGalleyAsync,
} from "../../Gallery/GallerySlice";
import {
  IncludedService,
  getMasterClassIncludedServiceAsync,
  includedServiceSelector,
} from "../../IncludedServices/IncludedServiceSlice";
import {
  MasterClassDetail,
  getMasterClassAsyc,
  masterClassSelector,
} from "../MasterClassSlice";
import "./MasterClassDetails.css";

import _ from "lodash";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import {
  CheckoutItem,
  MyOrder,
  createCheckoutSessionAsync,
  createOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import { MasterClassModal } from "../masterclassModal/MasterClassModal";
import { formatText } from "../../../utils/utils";
import { Button, Input, Modal } from "antd";
import {
  getWalletAsync,
  Wallet,
  walletSelector,
} from "../../Wallet/WalletSlice";
import { withSuccess } from "antd/es/modal/confirm";
import { useSelector } from "react-redux";
import axios from "axios";
import { MasterClass } from "../MasterClass";
import {
  MasterClassRequestDTO,
  createMasterClassRequestAsync,
} from "../MasterClassSlice";

export const MasterClassDetails = () => {
  const [currentCredits, setCurrentCredits] = useState<number>(0);
  const [walletId, setWalletId] = useState<number>(-1);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getWalletAsync()).then((response) => {
      const walletData = response?.payload;
      if (walletData) {
        setWalletId(walletData.id);
        setCurrentCredits(walletData.currentCredits);
      }
    });
  }, [dispatch]);
  const wallet = useAppSelector(
    (state) => walletSelector.selectById(state, walletId) ?? ({} as Wallet)
  );
  const masterClassDetailsRoute = useMatch("masterclass/:id/details");

  const [id, setId] = useState<number>(-1);

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const [liked, setLiked] = useState(false);

  let masterClass: MasterClassDetail = useAppSelector(
    (state) =>
      masterClassSelector.selectById(state, id) ?? ({} as MasterClassDetail)
  );
  let includedService: IncludedService[] = useAppSelector(
    includedServiceSelector.selectAll
  );

  let faq: Faq[] = useAppSelector(faqSelector.selectAll);

  let gallery: Gallery[] = useAppSelector(gallerySelector.selectAll);

  const imageList = gallery.map((el) => el.image);
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);

  let bookNowModalObj: MasterClassDetail = useAppSelector(
    (state) =>
      masterClassSelector.selectById(state, bookNowModalId) ??
      ({} as MasterClassDetail)
  );

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (isGalleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? imageList.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === imageList.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isGalleryOpen, imageList, currentImageIndex]);

  const capitalize = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatData = (dataString: string): string => {
    const data = parseISO(dataString);
    const formatoData = "EEE dd MMM";
    const dataFormattata = format(data, formatoData, { locale: it });

    const parole = dataFormattata.split(" ");
    const dataFormattataConMaiuscole = parole
      .map((parola) => capitalize(parola))
      .join(" ");

    return dataFormattataConMaiuscole;
  };

  const [isBookingLoading, setIsBookingLoading] = useState(false);

  const redirectToStripe = (stripeCheckoutURL: string) => {
    if (stripeCheckoutURL) {
      window.location.href = stripeCheckoutURL;
    }
  };

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);

  const createActionOnClick = (numPerson: number) => {
    const userInfo = localStorage.getItem("customerId");
    const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
    const tenant = localStorage.getItem("tenantId");
    const tenantId = tenant ? parseInt(tenant, 10) : 0;
    const orderData: MyOrder = {
      id: 0,
      tenantId: tenantId,
      bookingDate: new Date(hoursItalian),
      numberOfPeople: numPerson,
      status: 1,
      purchasedBy: userInfoId,
      masterClassId: bookNowModalId,
      profilePhoto: "",
    };
    store
      .dispatch(createOrderAsync({ myOrder: orderData }))
      .then((result) => {
        if (
          result.meta.requestStatus === "fulfilled" &&
          process.env.REACT_APP_PUBLIC_URL
        ) {
          const bookingId = result.payload.id;
          const checkoutItem: CheckoutItem = {
            successURL:
              process.env.REACT_APP_PUBLIC_URL +
              "masterclass/" +
              id +
              "/success/" +
              result.payload.id,
            cancelURL:
              process.env.REACT_APP_PUBLIC_URL +
              "masterclass/" +
              id +
              "/details",
          };
          store
            .dispatch(createCheckoutSessionAsync({ checkoutItem, bookingId }))
            .then((stripeResult: any) => {
              redirectToStripe(stripeResult.payload);
            });
        }
      })
      .catch(() => {
        ToastNotification({
          status: "error",
          description: "",
          toastId: "Booking not created",
          title: t("smartPlanDetail.toastNotification.networkError"),
        });
      })
      .finally(() => {
        setIsBookingLoading(false);
      });
    setIsBookingLoading(true);
  };

  useEffect(() => {
    if (masterClassDetailsRoute !== null) {
      if (masterClassDetailsRoute.params.id) {
        setId(parseInt(masterClassDetailsRoute.params.id));
        store.dispatch(
          getMasterClassAsyc({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassFaqsAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassIncludedServiceAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        store.dispatch(
          getMasterClassGalleyAsync({
            masterClassId: parseInt(masterClassDetailsRoute.params.id),
          })
        );
        return;
      }
    }
  }, [masterClassDetailsRoute]);

  const formatDataModal = (
    dataString: string
  ): { giorno: string; mese: string } => {
    const data = new Date(dataString);

    const giorno = data.getDate().toString();
    const mese = new Intl.DateTimeFormat("it", { month: "long" }).format(data);

    return { giorno, mese };
  };

  const navigate = useNavigate();
  const defaultValuePerson = 1;
  const myOrderSliceStatus = useAppSelector(selectMyOrdersSliceStatus);
  const isLoading = myOrderSliceStatus === "loading";

  const contactLabelRef = useRef<HTMLLabelElement>(null);

  const userInfo = useAppSelector((state: RootState) => state.userInfo);

  const userId = userInfo.ids[0];

  const user = userId ? userInfo.entities[userId] : null;

  const [numberOfPeople, setNumberOfPeople] = useState<number>(1);

  const [requestMessage, setRequestMessage] = useState<string>("");

  const handleSubmit = () => {
    if (!user || !user.id || isNaN(user.id)) {
      console.error("User ID non valido:", user ? user.id : "null");
      Modal.error({ content: "Errore: ID utente non valido." });
      return;
    }

    const userId = user.id;

    if (!masterClass || !masterClass.id) {
      console.error(
        "masterClass.id non valido:",
        masterClass ? masterClass.id : "null"
      );
      Modal.error({ content: "Errore: ID della MasterClass non valido." });
      return;
    }

    console.log("Dati utente:", user);
    console.log("masterClass:", masterClass);

    const customerName =
      user?.firstname && user?.lastname
        ? `${user.firstname} ${user.lastname}`
        : "Nome non disponibile";

    const customerPhone = user?.telephone || "Telefono non disponibile";
    const companyName = user?.tenant.businessName || "Azienda non disponibile";

    const requestData = {
      masterClassId: masterClass.id,
      customerName: customerName,
      customerEmail: user.email,
      customerPhone: customerPhone,
      companyName: companyName,
      serviceName: masterClass.title,
      personNumber: numberOfPeople,
      requestMessage: requestMessage,
    };


    if (!user || !user.id) {
      console.error("User ID non valido:", user.id);
      Modal.error({ content: "Errore: ID utente non valido." });
      return;
    }

    const tenant = localStorage.getItem("tenantId");

    const tenantId = tenant ? parseInt(tenant, 10) : 0;
    store
      .dispatch(createMasterClassRequestAsync({masterClassRequestDTO : requestData}))
      .then(() => {
        Modal.success({ content: "Richiesta inviata con successo!" });
      })
      .catch((error) => {
        console.error("Errore nell'invio della richiesta:", error);
        Modal.error({ content: "Errore nell'invio della richiesta. Riprova." });
      });
  };
  return (
    <>
      <HeaderMenu>
        <TopBar
          title={masterClass.title}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={"Master Class > " + masterClass.title}
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/masterclass")}
        />
      </HeaderMenu>
      <div className="masterclassdetail-container">
        <img
          className="rectangle-8"
          alt=""
          src={masterClass.image ? masterClass.image : undefined}
        />
        <div className="overlap-2">
          <div className="masterclassdetail-bady-page">
            <div className="title-services">
              {t("masterClassDetail.masterClassServiceComponent.service")}
            </div>
            <div className="flex-container-masterclass">
              {includedService.map(
                (el: {
                  title:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | Iterable<ReactNode>
                    | null
                    | undefined;
                  description:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | Iterable<ReactNode>
                    | null
                    | undefined;
                }) => (
                  <div>
                    <div className="service-title">{el.title}</div>
                    <div className="service-subtitle">{el.description}</div>
                  </div>
                )
              )}
            </div>
            <div
              className="description-masterclass"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {masterClass && (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      masterClass.description?.replace(/\s+/g, " ").trim() ??
                      "",
                  }}
                />
              )}
            </div>
            {masterClass.video && (
              <>
                <div className="masterclass-video">
                  {t("masterClassDetail.video")}
                </div>
                <div className="flex-container-video">
                  <iframe
                    width="100%"
                    height="360"
                    src={masterClass && masterClass.video}
                    title={masterClass && masterClass.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    border-radius="4px"
                  ></iframe>
                </div>
              </>
            )}
            {gallery && gallery.length > 0 && (
              <>
                <div className="title-gallery">
                  {t("masterClassDetail.gallery")}
                </div>
                <div className="master-class">
                  <div className="flex-container-gallery">
                    {gallery.map((item, index) => (
                      <div className="gallery-container" key={item.id}>
                        <img
                          className="gallery-image"
                          src={item.image}
                          alt={`${item.id}`}
                          onClick={() => openGallery(index)}
                        />
                      </div>
                    ))}
                  </div>
                  {isGalleryOpen && (
                    <div className="gallery-overlay">
                      <button className="close-button" onClick={closeGallery}>
                        x
                      </button>
                      <button
                        className="prev-button"
                        onClick={() =>
                          setSelectedImageIndex((prev) =>
                            prev === 0 ? imageList.length - 1 : prev - 1
                          )
                        }
                      >
                        <TwentyIcArrowLeft className="arrow-gallery" />
                      </button>
                      <button
                        className="next-button"
                        onClick={() =>
                          setSelectedImageIndex((prev) =>
                            prev === imageList.length - 1 ? 0 : prev + 1
                          )
                        }
                      >
                        <TwentyIcArrowRight className="arrow-gallery" />
                      </button>
                      <div className="gallery">
                        <img
                          src={imageList[selectedImageIndex]}
                          alt="img"
                          className="gallery-image-large"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

            {masterClass.city !== "null" && masterClass.city && (
              <>
                <div className="text-wrapper-21-map">
                  {t("masterClassDetail.map")}
                </div>
                <Map city={masterClass.city} />
              </>
            )}
            <div className="form-contatto-wrapper">
              <div className="frame-44">
                <h3>Contattaci</h3>
                <p>Parliamone per migliorare l'esperienza e definire le date</p>
              </div>
              <div className="contatto-smartplan">
                <label className="contatto-smartplan--label">
                  N. di persone
                </label>
                <Input
                  type="number"
                  min="1"
                  value={numberOfPeople}
                  onChange={(e) => setNumberOfPeople(Number(e.target.value))}
                />
                <label
                  ref={contactLabelRef}
                  className="contatto-smartplan--label"
                >
                  Scrivi la tua richiesta
                </label>
                <Input.TextArea
                  rows={8}
                  value={requestMessage}
                  onChange={(e) => setRequestMessage(e.target.value)}
                />
                <Button
                  className="contatto-smartplan--submit"
                  onClick={handleSubmit}
                >
                  Invia richiesta
                </Button>
              </div>
            </div>
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t(
                    "masterClassDetail.questionsAndAnswers.questionsAndAnswers"
                  )}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("masterClassDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("masterClassDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer-masterclass">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div>
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
          </div>{" "}
          <div className="container-summary">
            <div className="summary-wrapper">
              <SummaryComponent
                region={masterClass && masterClass.region}
                province={masterClass.province ? masterClass.province : " -- "}
                selectDate={"Richiedi informazioni"}
                startDate={
                  masterClass && masterClass.startDate
                    ? formatData(masterClass.startDate.toString())
                    : "Da definire"
                }
                endDate={
                  masterClass && masterClass.endDate
                    ? formatData(masterClass.endDate.toString())
                    : "Da definire"
                }
                destination={(masterClass && masterClass.city) ?? "--"}
                activity={
                  masterClass && masterClass.placesLeft
                    ? masterClass.placesLeft +
                      t("masterClassDetail.componentSummary.available")
                    : "--"
                }
                price={(masterClass && masterClass.price) ?? 0}
                destinationText={t(
                  "masterClassDetail.componentSummary.destination"
                )}
                activityText={t("masterClassDetail.componentSummary.freeSeats")}
                summary={t("masterClassDetail.componentSummary.summary")}
                start={t("masterClassDetail.componentSummary.start")}
                end={t("masterClassDetail.componentSummary.end")}
                startingFrom={t(
                  "masterClassDetail.componentSummary.startingFrom"
                )}
                downPayment={t(
                  "masterClassDetail.componentSummary.downPayment"
                )}
                descriptionP1={t(
                  "masterClassDetail.componentSummary.descriptionP1"
                )}
                descriptionP2={t(
                  "masterClassDetail.componentSummary.descriptionP2"
                )}
                onClick={() => {
                  // Scroll verso il label
                  if (contactLabelRef.current) {
                    contactLabelRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }
                  // setBookNowModalId(id); // Puoi mantenere altre logiche
                }}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "masterClassDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("masterClassDetail.componentAssistantBox.whatsApp")}
                date={t("masterClassDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", firstName ?? "")
                  .replace("lastName*", lastName ?? "")
                  .replace("companyName*", businessName ?? "")}
                disabled={undefined}
              />
            </div>

            {/* {!_.isEmpty(bookNowModalObj) && (
              <MasterClassModal
                onClose={() => {
                  navigate("/masterclass/" + bookNowModalObj.id + "/details");
                  setBookNowModalId(-1);
                }}
                title={t("masterClassDetail.bookNowModal.title")}
                month="Settembre"
                startDay="--"
                endDay="--"
                deposit={bookNowModalObj.deposit}
                price={bookNowModalObj.price}
                totalCredit={currentCredits} // Passiamo i crediti correnti
                onClick={(npersone) =>
                  createActionOnClick(npersone ?? defaultValuePerson)
                }
                isActionPerforming={isLoading}
              />
            )} */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
