import HttpClient from "../../utils/HttpClient";

import { CheckoutItem, MyOrder } from "./MyOrdersSlice";

export default class MyOrdersRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getAllOrders(queryString?: string) {
    let customerId = localStorage.getItem("customerId");
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/users/" +
        customerId +
        "/bookings" +
        (queryString ?? "")
    );
  }

  public getMyOrders(queryString?: string) {
    let customerId = localStorage.getItem("customerId");
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/users/" +
        customerId +
        "/myBookings" +
        (queryString ?? "")
    );
  }

  public getUserAssociatedOrders(queryString?: string) {
    let customerId = localStorage.getItem("customerId");
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.get(
      "tenants/" +
        tenantId +
        "/users/" +
        customerId +
        "/associatedBookings" +
        (queryString ?? "")
    );
  }

  public getOrderById(orderId: number) {
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.get("tenants/" + tenantId + "/bookings/" + orderId);
  }

  public createOrder(myOrder: MyOrder) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/booking",
      myOrder
    );
    
  }

  public createWellnessOrder(myOrder: MyOrder) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/wellnessBooking",
      myOrder
    );
  }

  public createCheckoutSession(checkoutItem: CheckoutItem, bookingId: number) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/checkout/session/bookings/" +
        bookingId,
      checkoutItem
    );
  }

  public createCheckoutSessionForCredits(checkoutItem: CheckoutItem) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/checkout/session",
      checkoutItem
    );
  }

  public getUserAssociatedBookings(queryString?: string) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/users/" +
        userId +
        "/associatedBookings" +
        queryString
    );
  }

  public createAssociation(bookingId: number, queryString: string) {
    let customerId = localStorage.getItem("customerId");
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.post(
      "tenants/" +
        tenantId +
        "/users/" +
        customerId +
        "/bookings/" +
        bookingId +
        "?userId=" +
        (queryString ?? "")
    );
  }

  public deleteAssociation(bookingId: number, queryString: string) {
    let customerId = localStorage.getItem("customerId");
    let tenantId = localStorage.getItem("tenantId");
    return this.instance.delete(
      "tenants/" +
        tenantId +
        "/users/" +
        customerId +
        "/bookings/" +
        bookingId +
        "?userId=" +
        (queryString ?? "")
    );
  }
}
