import HttpClient from "../../utils/HttpClient";

import _ from "lodash";
import { MasterClassRequestDTO } from "./MasterClassSlice";

export default class MasterClassRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getMasterClassActivityTail(queryString?: string) {
    return this.instance.get("masterClassesView" + (queryString ?? ""));
  }

  public getMasterClass(masterClassId?: number) {
    return this.instance.get("/masterClasses/masterclass/" + masterClassId);
  }

  public getAllMasterClasses() {
    const path = "/masterClasses";
    return this.instance.get(path);
  }

  public createMasterClassRequest (request: MasterClassRequestDTO){
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");
  
    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/masterclasses", 
      request 
    );
  
  }

}