import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import UserInfoRepository from "./UserInfoRepository";
import _ from "lodash";
import { Config } from "../../config/Config";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";

export interface UserInfo {
  businessName: String;
  tenant: any;
  companyName: any;
  phone: any;
  name: any;
  id: number;
  firstname: string;
  lastname: string;
  activationDate: Date;
  lastAccess: Date;
  active: boolean;
  email: string;
  password: string;
  fiscalCode: string;
  profilePhoto: string;
  role: string;
  city: string;
  telephone: string;
  creationDate: Date;
  admin: boolean;
  lastUpdate: Date;
  country: string;
  virtualOfficeURL: string;
  personalOfficeURL?: string;
}

//#endRegion Type

//#region API

export const verifyUserInviteAsync = createAsyncThunk(
  "userInfo/verifyInvite",
  async ({ email, inviteCode }: any) => {
    const userInfoRepository = new UserInfoRepository();
    const response = await userInfoRepository.verifyUserInvite(
      email,
      inviteCode
    );
    const userInfo = _.get(response, Config.USER_INFO_RESPONSE);
    return userInfo;
  }
);

export const getUserInfoAsync = createAsyncThunk(
  "userInfo/getUserInfo",
  async ({ userInfoId, tenantId }: any) => {
    const userInfoRepository = new UserInfoRepository();
    const response = await userInfoRepository.getUserInfo(userInfoId, tenantId);
    const validation = _.get(response, Config.USER_INFO_RESPONSE);
    return validation;
  }
);

export const createUserInfoAsync = createAsyncThunk(
  "userInfo/createUserInfo",
  async ({ data, tenantId, inviteCode }: any) => {
    const userInfoRepository = new UserInfoRepository();
    await userInfoRepository.createUserInfo(data, tenantId, inviteCode);
  }
);

export const partiallyUpdateUserInfoAsync = createAsyncThunk(
  "userInfo/partiallyUpdateUserInfo",
  async ({ userInfo, tenantId }: any) => {
    const userInfoRepository = new UserInfoRepository();
    const response = await userInfoRepository.updateUserInfo(
      userInfo,
      tenantId
    );
    const userinfo = _.get(response, Config.UPDATE_USER_INFO_RESPONSE);
    return userinfo;
  }
);

//#endRegion API

//#region Slice

const userInfoAdapter = createEntityAdapter<UserInfo>({
  selectId: (userInfo) => userInfo.id,
});

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: userInfoAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    userInfoEmptyState: (state) => {
      userInfoAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    userInfoReasonCodeAndStatusEmptyState: (state) => {
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          userInfoAdapter.upsertOne(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getUserInfoAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(getUserInfoAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = "";
      })
      .addCase(
        createUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          state.statys = "idle";
          state.reasonCode = "created";
        }
      )
      .addCase(createUserInfoAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(createUserInfoAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })

      .addCase(
        partiallyUpdateUserInfoAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload) {
            userInfoAdapter.upsertOne(state, action.payload);
          }
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.PATCH;
        }
      )
      .addCase(partiallyUpdateUserInfoAsync.pending, (state: any) => {
        state.statys = "loading";
        state.reasonCode = "";
      })
      .addCase(partiallyUpdateUserInfoAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const userInfoSelector = userInfoAdapter.getSelectors<RootState>(
  (state) => state.userInfo
);

export const { userInfoEmptyState, userInfoReasonCodeAndStatusEmptyState } =
  userInfoSlice.actions;
export const selectUserInfoSliceStatus = (state: any) => state.userInfo.status;
export const selectUserInfoSliceReasonCode = (state: any) =>
  state.userInfo.reasonCode;

//#endRegion Status

export default userInfoSlice.reducer;
