import HttpClient from "../../utils/HttpClient";
import { SmartPlanInfoDTO } from "./SmartPlanSlice";

export default class SmartPlanRepository extends HttpClient {
  constructor() {
    super(true);
  }

  public getSmartPlanActivityTail(queryString?: string) {
    return this.instance.get("smartPlans" + (queryString ?? ""));
  }

  public getSmartPlan(smartPlanId: number) {
    const path = "/smartPlans/smartPlan/" + smartPlanId;
    return this.instance.get(path);
  }

  public createSmartPlanInfo(request: SmartPlanInfoDTO) {
    const tenantId = localStorage.getItem("tenantId");
    const userId = localStorage.getItem("customerId");

    return this.instance.post(
      "tenants/" + tenantId + "/users/" + userId + "/smartPlanInfo",
      request
    );
  }
}
