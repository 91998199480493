//#region Type

import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { Config } from "../../config/Config";
import _ from "lodash";
import { RootState } from "../../app/store";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import WalletRepository from "./WalletRepository";

export interface Wallet {
  id: number;
  currentCredits: number;
  totalCredits: number;
  userId: number;
  tenantId: number;
}

export interface WalletChargeRequest {
  stripeId: string;
  credits: number;
}

//#endRegion Type

//#region API

export const getWalletAsync = createAsyncThunk("wallet/getWallet", async () => {
  const walletRepository = new WalletRepository();
  const response = await walletRepository.getWallet();
  const wallet = _.get(response, Config.WALLET_RESPONSE);
  return wallet;
});

export const getWalletsAsync = createAsyncThunk(
  "wallet/getWallets",
  async () => {
    const walletRepository = new WalletRepository();
    const response = await walletRepository.getWallets();
    const wallet = _.get(response, Config.WALLET_RESPONSE);
    return wallet;
  }
);

export const chargeWalletAsync = createAsyncThunk(
  "wallet/chargeWallet",
  async (data: { walletChargeRequest: any }) => {
    const walletRepository = new WalletRepository();
    const response = await walletRepository.chargeWallet(
      data.walletChargeRequest
    );
    const wallet = _.get(response, Config.WALLET_RESPONSE);
    return wallet;
  }
);

export const getRateAsync = createAsyncThunk(
  "wallet/getRate",
  async (type: string) => {
    const walletRepository = new WalletRepository();
    const response = await walletRepository.getRate(type);
    return response.data.rate;
  }
);

//#endRegion API

//#region Slice

const walletAdapter = createEntityAdapter<Wallet>({
  selectId: (wallet) => wallet.id,
});

export const walletSlice = createSlice({
  name: "wallet",
  initialState: walletAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    walletEmptyState: (state) => {
      walletAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getWalletAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          walletAdapter.setAll(state, [action.payload]);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWalletAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getWalletAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getWalletsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          walletAdapter.setAll(state, action.payload);
          state.statys = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getWalletsAsync.pending, (state: any) => {
        state.statys = "loading";
      })
      .addCase(getWalletsAsync.rejected, (state: any) => {
        state.statys = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const { walletEmptyState } = walletSlice.actions;

export const walletSelector = walletAdapter.getSelectors<RootState>(
  (state) => state.wallet
);

export const selectWalletSliceStatus = (state: any) => state.wallet.status;
export const selectWalletSliceReasonCode = (state: any) =>
  state.wallet.reasonCode;

//#endRegion Status

export default walletSlice.reducer;
