import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Input,
  MenuProps,
  Radio,
  Space,
  Table,
  Tooltip,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { ApexOptions } from "apexcharts";
import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { BaseTab } from "../../ui/components/BaseTab";
import { TwentyIcHandServices } from "../../ui/components/TwentyIcHandServices";
import { IconInfo } from "../../ui/components/icons/IconInfo";
import { IconTik } from "../../ui/components/icons/IconTik";
import { ToastNotification } from "../../ui/components/utils/ToastNotification";
import {
  capitalizeFirstLetter,
  formatNumber,
  formattedData,
  monthTranslatorFromNumber,
} from "../../utils/utils";
import "../Meetings/Meetings.css";
import { SearchInput } from "../../ui/components/Input";
import { ModalWrapper } from "../../ui/components/Modals/ModalWrapper";
import { ModalBody } from "../../ui/components/Modals/ModalBody";
import Tag from "../../ui/components/Tag/Tag";
import { ModalFooter } from "../../ui/components/Modals/ModalFooter";
import {
  ServiceViewSlice,
  getServicesByProviderAsync,
  wellnessServicesViewSelector,
} from "../Wellness/WellnessSlice/ServiceViewSlice";
import {
  Meeting,
  MeetingFilters,
  MeetingRequest,
  MeetingSummary,
  getMeetingsAsync,
  getWellnessMeetingsAsync,
  meetingSelector,
  selectMeetingSliceReasonCode,
  selectMeetingSliceStatus,
  updateMeetingsStatusAsync,
} from "./MeetingSlice";
import {
  MeetingsForReportSlice,
  getMeetingsReport,
} from "./MeetingsForReport/MeetingsForReportSlice";

export const Meetings = () => {
  const navigate = useNavigate();
  const [elementSelected, setElementSelected] = useState(0);
  const [dataReportForCurrentYear, setDataReportForCurrentYear] =
    useState<MeetingsForReportSlice>();
  const meetings: MeetingSummary[] = useAppSelector(meetingSelector.selectAll);
  const [meetingsSelected, setMeetingsSelected] = useState<Meeting[]>([]);

  const meetingsSliceStatus = useAppSelector(selectMeetingSliceStatus);
  const meetingReasonCode = useAppSelector(selectMeetingSliceReasonCode);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  const genericParams: MeetingFilters = {
    firstName: null,
    lastName: null,
    service: null,
    months: null,
    meetingInvoiceStatusEnum: null,
    meetingStatusEnum: null,
    deleted: false,
  };

  //region MODALSCONTROLLERS

  const [filterModalOpened, setFilterModalOpened] = useState(false);
  const [servicesModalOpened, setServicesModalOpened] = useState(false);
  const [invoiceModalOpened, setInvoiceModalOpened] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  //endregion MODALSCONTROLLERS
  //region USERVARIABLES

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");

  //endregion USERVARIABLES
  //region SERVICESVARIABLES

  const servicesByProvider: ServiceViewSlice[] = useAppSelector(
    wellnessServicesViewSelector.selectAll
  );
  const [servicesSelected, setServicesSelected] = useState("");

  const servicesList: MenuProps["items"] = [];

  //endregion SERVICESVARIABLES
  //region MONTHVARIABLES

  const [monthsSelected, setMonthsSelected] = useState<string[]>([]);

  //list for dropdown
  const dropdownOrderList: MenuProps["items"] = [
    {
      label: "A - Z",
      key: "A - Z",
    },
    {
      label: "Prezzo discendente",
      key: "Prezzo discendente",
    },
    {
      label: "Prezzo ascendente",
      key: "Prezzo ascendente",
    },
    {
      label: "Ultimi inseriti",
      key: "Ultimi inseriti",
    },
    {
      label: "Primi inseriti",
      key: "Primi inseriti",
    },
  ];

  const dropdownMonthsList: MenuProps["items"] = [
    {
      label: (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              gap: "5px",
              height: "0px",
              paddingTop: "15px",
            }}
          >
            Tutti i mesi{" "}
            {monthsSelected.length === 0 && <IconTik className={undefined} />}{" "}
          </div>
          <br />
          <div className="divider"></div>
        </>
      ),
      key: "ALL",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.january"))}{" "}
            {isMonthSelected("1") && (
              <div style={{ paddingLeft: "20px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "1",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.february"))}{" "}
            {isMonthSelected("2") && (
              <div style={{ paddingLeft: "18px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "2",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.march"))}{" "}
            {isMonthSelected("3") && (
              <div style={{ paddingLeft: "33px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "3",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.april"))}{" "}
            {isMonthSelected("4") && (
              <div style={{ paddingLeft: "36px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "4",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.may"))}{" "}
            {isMonthSelected("5") && (
              <div style={{ paddingLeft: "25px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "5",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.june"))}{" "}
            {isMonthSelected("6") && (
              <div style={{ paddingLeft: "28px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "6",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.july"))}{" "}
            {isMonthSelected("7") && (
              <div style={{ paddingLeft: "36px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "7",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.august"))}{" "}
            {isMonthSelected("8") && (
              <div style={{ paddingLeft: "28px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "8",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.september"))}{" "}
            {isMonthSelected("9") && (
              <div style={{ paddingLeft: "10px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "9",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.october"))}{" "}
            {isMonthSelected("10") && (
              <div style={{ paddingLeft: "25px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "10",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.november"))}{" "}
            {isMonthSelected("11") && (
              <div style={{ paddingLeft: "10px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "11",
    },
    {
      label: (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {capitalizeFirstLetter(t("month.december"))}{" "}
            {isMonthSelected("12") && (
              <div style={{ paddingLeft: "15px" }}>
                <IconTik className={undefined} />
              </div>
            )}
          </div>
        </>
      ),
      key: "12",
    },
  ];

  //list for select
  const selectMonthsList = [
    {
      label: capitalizeFirstLetter(t("month.january")),
      value: "01",
    },
    {
      label: capitalizeFirstLetter(t("month.february")),
      value: "02",
    },
    {
      label: capitalizeFirstLetter(t("month.march")),
      value: "03",
    },
    {
      label: capitalizeFirstLetter(t("month.april")),
      value: "04",
    },
    {
      label: capitalizeFirstLetter(t("month.may")),
      value: "05",
    },
    {
      label: capitalizeFirstLetter(t("month.june")),
      value: "06",
    },
    {
      label: capitalizeFirstLetter(t("month.july")),
      value: "07",
    },
    {
      label: capitalizeFirstLetter(t("month.august")),
      value: "08",
    },
    {
      label: capitalizeFirstLetter(t("month.september")),
      value: "09",
    },
    {
      label: capitalizeFirstLetter(t("month.october")),
      value: "10",
    },
    {
      label: capitalizeFirstLetter(t("month.november")),
      value: "11",
    },
    {
      label: capitalizeFirstLetter(t("month.december")),
      value: "12",
    },
  ];

  //endRegion MONTHVARIABLES
  //region MEETINGSTATUSES

  const [acceptedByUser, setAcceptedByUser] = useState<boolean | string>("ALL");
  const [invoiced, setInvoiced] = useState<boolean | string>("ALL");
  const [meetingExecuted, setMeetingExecuted] = useState(false);

  const [isFilteredByInvoiced, setIsFilteredByInvoiced] = useState(false);
  const [isFilteredByMeeting, setIsFilteredByMeeting] = useState(false);

  //endRegion MEETINGSTATUSES
  //region ANNOTATIONS

  const [annotiationsMeetingStatus, setAnnotationsMeetingStatus] = useState("");
  const reasons: MenuProps["items"] = [
    {
      key: "Ho avuto un imprevisto",
      label: (
        <div className="meetings-table-header-filter-text-one">
          Ho avuto un imprevisto
        </div>
      ),
    },
    {
      key: "L’utente non si è presentato",
      label: (
        <div className="meetings-table-header-filter-text-one">
          L'utente non si è presentato
        </div>
      ),
    },
    {
      key: "Altro",
      label: <div className="meetings-table-header-filter-text-one">Altro</div>,
    },
  ];
  const [reasonSelected, setReasonSelected] = useState("");

  //endregion ANNOTATIONS
  //region TABLECOMPONENTS

  type SortOrder =
    | "A - Z"
    | "Prezzo discendente"
    | "Prezzo ascendente"
    | "Ultimi inseriti"
    | "Primi inseriti";
  const [sortOrder, setSortOrder] = useState<SortOrder>("Primi inseriti");

  const onOrderListClick: MenuProps["onClick"] = ({ key }) => {
    setSortOrder(key as SortOrder);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any, newSelectedRows: Meeting[]) => {
      console.log("Selezione modificata:", newSelectedRowKeys, newSelectedRows);
      setSelectedRowKeys(newSelectedRowKeys);
      setMeetingsSelected(newSelectedRows); // Aggiorna lo stato con gli oggetti Meeting selezionati
    },
    getCheckboxProps: (record: Meeting) => {
      if (!record) return {};

      return {
        disabled:
          record.meetingStatusEnum !== "APPROVED" ||
          record.meetingInvoiceStatusEnum !== "NOT_INVOICED",
        name: record.meetingName,
        id: record.id ? record.id.toString() : "", 
      };
    },
  };

  const columns: ColumnsType<Meeting> = [
    {
      title: "SERVIZIO",
      dataIndex: "meetingName",
      render: (text, record) => {
        return (
          <>
            <div className="meeting-table-tworows-record">
              <div className="meeting-table-primary-text">
                {record.meetingName ? record.meetingName : null}{" "}
              </div>
              <div className="meeting-table-secondary-text">
                {record.meetingExecution
                  ? formattedData(record.meetingExecution)
                  : null}{" "}
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "UTENTE",
      dataIndex: "firstName",
      render: (text, record) => {
        return (
          <>
            <div className="meeting-table-primary-text">
              {record.firstName && record.lastName
                ? `${record.firstName} ${record.lastName}`
                : null}{" "}
            </div>
          </>
        );
      },
    },
    {
      title: (
        <>
          <Tooltip title={"Acquisto del dipendente da schedulare"}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              STATO DELL'ACQUISTO
              <IconInfo color={"#14ADCC"} className={undefined} />
            </div>
          </Tooltip>
        </>
      ),
      dataIndex: "meetingStatusEnum",
      render: (el, record) => {
        if (!record.meetingName) {
          return null; 
        }
        return (
          <>
            {el === "PURCHASED" ? (
              <Tag textOne="In attesa di prenotazione" isRem={false}></Tag>
            ) : (
              <Tag textOne="Prenotato" isRem={false}></Tag>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <Tooltip
            title={
              "Conferma da parte del dipendente che il servizio è stato realizzato"
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              STATO DEL SERVIZIO
              <IconInfo color={"#14ADCC"} className={undefined} />
            </div>
          </Tooltip>
        </>
      ),
      dataIndex: "meetingStatusEnum",
      render: (el, record) => {
        return (
          <>
            {el === "PURCHASED" && <b>-</b>}
            {el === "PROVIDER_APPROVED" && (
              <Tag textOne="In attesa di approvazione" isRem={false}></Tag>
            )}
            {el === "APPROVED" && (
              <Tag textOne="Completato" isRem={false}></Tag>
            )}
            {el === "NOT_APPROVED" && (
              <div
                className="meetings-button-table-confirm"
                onClick={() => {
                  setMeetingsSelected([]);
                  setMeetingsSelected([...meetingsSelected, record as Meeting]);
                  setServicesModalOpened(!servicesModalOpened);
                }}
              >
                <div className="meetings-table-header-filter-text-two">
                  Conferma
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: (
        <>
          <Tooltip
            title={
              "Fattura associata a questo servizio. Puoi consultare la sezione delle fatture."
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              FATTURA
              <IconInfo color={"#14ADCC"} className={undefined} />
            </div>
          </Tooltip>
        </>
      ),
      dataIndex: "meetingInvoiceStatusEnum",
      render: (el, record) => {
        return (
          <>
            {el === "NOT_INVOICED" &&
              record.meetingStatusEnum === "APPROVED" && (
                <div className="meetings-button-table-confirm ">
                  <div className="meetings-table-header-filter-text-two">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                      onClick={() => {
                        setMeetingsSelected([]);
                        let temp = [];
                        temp.push(record);
                        setMeetingsSelected(temp);
                        setInvoiceModalOpened(!invoiceModalOpened);
                      }}
                    >
                      <IconTik className={undefined} color="grey" />
                      <b>Fatturato</b>
                    </div>
                  </div>
                </div>
              )}
            {el === "COMPLETED" && (
              <div className="meeting-table-tworows-record">
                <div className="meeting-table-primary-text">Fatturato</div>
                <div className="meeting-table-secondary-text">
                  {record.invoiceDate
                    ? formattedData(record.invoiceDate)
                    : null}{" "}
                </div>
              </div>
            )}
            {record.meetingStatusEnum !== "APPROVED" && <b>-</b>}
          </>
        );
      },
    },
    {
      title: "FAT. SALDATA",
      dataIndex: "meetingInvoiceStatusEnum",
      render: (status) => {
        return (
          <>
            {status === "PENDING" && (
              <Tag textOne="In attesa di saldo" isRem={false}></Tag>
            )}
            {status === "COMPLETED" && (
              <Tag textOne="Saldato" isRem={false}></Tag>
            )}
            {status === "NOT_INVOICED" && <b>-</b>}
          </>
        );
      },
    },
    {
      title: "€ / SERVIZIO",
      dataIndex: "invoicePrice",
      render: (price) => {
        return price ? (
          <div className="meeting-table-primary-text">{price + ",00 €"}</div>
        ) : null;
      },
    },
  ];

  //#endRegion TABLECOMPONENTS
  //#region USEEFFECTS

  useEffect(() => {
    !servicesModalOpened && setMeetingsSelected([]);
  }, [servicesModalOpened]);

  useEffect(() => {
    !invoiceModalOpened && setMeetingsSelected([]);
  }, [invoiceModalOpened]);

  useEffect(() => {
    

    store.dispatch(
      getWellnessMeetingsAsync()
    ); 

    store.dispatch(
      getServicesByProviderAsync({
        tenantId: parseInt(localStorage.getItem("tenantId") ?? "0"), 
        userId: parseInt(localStorage.getItem("customerId") ?? "0"), 
      })
    );
  }, []);

  useEffect(() => {
    if (servicesByProvider.length > 0) {
      servicesByProvider.map((e) => {
        servicesList.push({
          key: e.id,
          label: e.name,
        });
      });
    }
  }, [servicesByProvider]);

  useEffect(() => {
    clearFilters();
    if (elementSelected === 1) {
      store.dispatch(getMeetingsReport(currentYear)).then((reponse) => {
        if (reponse && reponse.payload)
          setDataReportForCurrentYear(reponse.payload);
      });
    }
  }, [elementSelected]);

  useEffect(() => {
    const newSelectedRowKeys = meetingsSelected.map((meeting) => meeting.id);
    setSelectedRowKeys(newSelectedRowKeys);
  }, [meetingsSelected]);

  //#endRegion USEEFFECTS
  //#region FUNCTIONS

  function tagRender(props: any) {
    const { label, value, closable, onClose } = props;

    return (
      <div style={{ paddingTop: "2px", paddingRight: "4px" }}>
        <Tag isRemoved={onClose} textOne={label} />
      </div>
    );
  }

  function filterMeetings(
    meetings: any[], 
    searchKey: string, 
    invoiced: boolean, 
    meeting: boolean 
  ) {
    let sortedMeetings = null;

    switch (sortOrder) {
      case "A - Z":
        sortedMeetings = _.sortBy(meetings, (x) => x.meetingName);
        break;
      case "Prezzo discendente":
        sortedMeetings = _.sortBy(meetings, (x) => x.invoicePrice).reverse();
        break;
      case "Prezzo ascendente":
        sortedMeetings = _.sortBy(meetings, (x) => x.invoicePrice);
        break;
      case "Ultimi inseriti":
        sortedMeetings = _.sortBy(meetings, (x) => x.meetingExecution);
        break;
      case "Primi inseriti":
        sortedMeetings = _.sortBy(
          meetings,
          (x) => x.meetingExecution
        ).reverse();

        break;
    }

    return sortedMeetings.filter((x) => {
      let matchesSearchKey = searchKey
        ? x.meetingName
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          x.firstName
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase()) ||
          x.lastName
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey.toLocaleLowerCase())
        : true;

      let tmpDate = new Date(x.meetingExecution);
      let matchesMonths =
        monthsSelected.filter((e) => e === (tmpDate.getMonth() + 1).toString())
          .length > 0;

      let matchesInvoiced = invoiced
        ? x.meetingInvoiceStatusEnum === "NOT_INVOICED" &&
          x.meetingStatusEnum === "APPROVED"
        : true;

      let matchesMeeting = meeting
        ? x.meetingStatusEnum === "NOT_APPROVED"
        : true;

      return (
        matchesSearchKey &&
        matchesInvoiced &&
        matchesMeeting &&
        (monthsSelected.length > 0 ? matchesMonths : true)
      );
    });
  }

  function clearFilters() {
    setAcceptedByUser("ALL");
    setInvoiced("ALL");
    setFirstName("");
    setLastname("");
    setServicesSelected("");
    setMonthsSelected([]);
    setSortOrder("Primi inseriti");
    setIsFilteredByMeeting(false);
    setIsFilteredByInvoiced(false);
  }

  function totalInvoice() {
    let total = 0;
    filterMeetings(
      meetings,
      searchKey,
      isFilteredByInvoiced,
      isFilteredByMeeting
    ).map((e) => {
      total = total + e.invoicePrice;
    });
    return total;
  }

  function clearMeetingModalFilters() {
    setReasonSelected("");
    setAnnotationsMeetingStatus("");
  }

  function isMonthSelected(key: string) {
    if (monthsSelected.filter((e) => e === key).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  //#region PARAMS BUILDERS

  function meetingSearchParamBuilderAndCallAsync() {
    let monthsArrayStringified = "";
    if (monthsSelected.length > 0) {
      monthsSelected.map((monthString) => {
        if (monthsArrayStringified !== "") {
          monthsArrayStringified =
            monthsArrayStringified +
            "," +
            new Date().getFullYear().toString() +
            "-" +
            monthString;
        } else {
          monthsArrayStringified =
            new Date().getFullYear().toString() + "-" + monthString;
        }
      });
    }
    const params: MeetingFilters = {
      firstName: firstName !== "" ? firstName : null,
      lastName: lastName !== "" ? lastName : null,
      service: servicesSelected !== "" ? servicesSelected : null,
      months: monthsArrayStringified !== "" ? monthsArrayStringified : null,
      meetingInvoiceStatusEnum:
        invoiced !== "ALL" ? (!invoiced ? "NOT_INVOICED" : null) : null,
      meetingStatusEnum:
        acceptedByUser !== "ALL"
          ? !acceptedByUser
            ? "PROVIDER_APPROVED"
            : null
          : null,
      deleted: false,
    };
    store.dispatch(getMeetingsAsync({ filters: params }));
    clearFilters();
  }

  function meetingStatusParamsBuilderAndCallAsync() {
    let requests: MeetingRequest[] = [];
    if (meetingsSelected.length === 1) {
      const request: MeetingRequest = {
        id: meetingsSelected[0].id,
        annotation:
          annotiationsMeetingStatus !== ""
            ? annotiationsMeetingStatus
            : reasonSelected,
        meetingStatusEnum: meetingExecuted ? "PROVIDER_APPROVED" : null,
        meetingInvoiceStatusEnum: null,
      };
      requests.push(request);
    }
    requests.length > 0 &&
      store
        .dispatch(updateMeetingsStatusAsync({ meetingsRequests: requests }))
        .then((result) => {
          if (result.meta.requestStatus === "fulfilled") {
            ToastNotification({
              status: "success",

              description: "",

              toastId: "Appuntamenti aggiornati con successo",

              title: t("toastNotification.successfully"),
            });
          } else {
            ToastNotification({
              status: "error",

              description: "",

              toastId: "Errore nell'aggiornamento degli appuntamenti",

              title: t("toastNotification.networkError"),
            });
          }
          store.dispatch(getMeetingsAsync({ filters: genericParams }));
        });

    store.dispatch(getMeetingsAsync({ filters: genericParams }));

    store
      .dispatch(updateMeetingsStatusAsync({ meetingsRequests: requests }))
      .then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          ToastNotification({
            status: "success",
            description: "",
            toastId: "Appuntamenti aggiornati con successo",
            title: t("toastNotification.successfully"),
          });
        } else {
          ToastNotification({
            status: "error",
            description: "",
            toastId: "Errore nell'aggiornamento degli appuntamenti",
            title: t("toastNotification.networkError"),
          });
        }
        store.dispatch(getMeetingsAsync({ filters: genericParams }));
      });
    clearMeetingModalFilters();
  }

  function meetingInvoiceStatusParamsBuilderAndCallAsync() {
    let requests: MeetingRequest[] = [];
    if (meetingsSelected.length !== 0) {
      meetingsSelected.map((el) => {
        const request: MeetingRequest = {
          id: el.id,
          annotation: null,
          meetingStatusEnum: null,
          meetingInvoiceStatusEnum: "PENDING",
        };
        requests.push(request);
      });
    }
    requests.length > 0 &&
      store
        .dispatch(updateMeetingsStatusAsync({ meetingsRequests: requests }))
        .then((result) => {
          if (result.meta.requestStatus === "fulfilled") {
            ToastNotification({
              status: "success",

              description: "",

              toastId: "Appuntamenti aggiornati con successo",

              title: t("toastNotification.successfully"),
            });
          } else {
            ToastNotification({
              status: "error",
              description: "",
              toastId: "Errore nell'aggiornamento degli appuntamenti",
              title: t("toastNotification.networkError"),
            });
          }
          store.dispatch(getMeetingsAsync({ filters: genericParams }));
        });
  }

  //#endRegion PARAMS BUILDERS
  //#endRegion FUNCTIONS

  const options: ApexOptions = {
    chart: {
      height: 350,
    },
    yaxis: {
      show: false,
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontSize: "16px",
          fontFamily: "Red Hat Display",
          fontWeight: 600,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: ["#35b39e"],
    dataLabels: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
  };

  const isMounth = (
    currentMonth: number,
    currentDay: number,
    month: number
  ) => {
    if (month < currentMonth) {
      return "#35b39e";
    } else {
      return "#E9E9EA";
    }
  };

  const series: ApexOptions["series"] = [
    {
      name: "Guadagno in euro",
      data: [
        {
          x: "Gennaio",
          y: dataReportForCurrentYear?.profitForMonth?.JANUARY,
          fillColor: isMounth(currentMonth, currentDay, 1),
        },
        {
          x: "Febbraio",
          y: dataReportForCurrentYear?.profitForMonth?.FEBRUARY,
          fillColor: isMounth(currentMonth, currentDay, 2),
        },
        {
          x: "Marzo",
          y: dataReportForCurrentYear?.profitForMonth?.MARCH,
          fillColor: isMounth(currentMonth, currentDay, 3),
        },
        {
          x: "Aprile",
          y: dataReportForCurrentYear?.profitForMonth?.APRIL,
          fillColor: isMounth(currentMonth, currentDay, 4),
        },
        {
          x: "Maggio",
          y: dataReportForCurrentYear?.profitForMonth?.MAY,
          fillColor: isMounth(currentMonth, currentDay, 5),
        },
        {
          x: "Giugno",
          y: dataReportForCurrentYear?.profitForMonth?.JUNE,
          fillColor: isMounth(currentMonth, currentDay, 6),
        },
        {
          x: "Luglio",
          y: dataReportForCurrentYear?.profitForMonth?.JULY,
          fillColor: isMounth(currentMonth, currentDay, 7),
        },
        {
          x: "Agosto",
          y: dataReportForCurrentYear?.profitForMonth?.AUGUST,
          fillColor: isMounth(currentMonth, currentDay, 8),
        },
        {
          x: "Settembre",
          y: dataReportForCurrentYear?.profitForMonth?.SEMPTEMBER,
          fillColor: isMounth(currentMonth, currentDay, 9),
        },
        {
          x: "Ottobre",
          y: dataReportForCurrentYear?.profitForMonth?.OCTOBER,
          fillColor: isMounth(currentMonth, currentDay, 10),
        },
        {
          x: "Novembre",
          y: dataReportForCurrentYear?.profitForMonth?.NOVEMBER,
          fillColor: isMounth(currentMonth, currentDay, 11),
        },
        {
          x: "Dicembre",
          y: dataReportForCurrentYear?.profitForMonth?.DECEMBER,
          fillColor: isMounth(currentMonth, currentDay, 12),
        },
      ],
    },
  ];

  const onReasonClick: MenuProps["onClick"] = ({ key }) => {
    setReasonSelected(key);
  };

  const onServiceClick: MenuProps["onClick"] = ({ key }) => {
    setServicesSelected(key);
  };

  const monthsHandleChange = (value: string[], record: any) => {
    setMonthsSelected(value);
  };

  const dropdownMonthsHandleChange: MenuProps["onClick"] = ({ key }) => {
    if (key === "ALL") {
      setMonthsSelected([]);
    } else {
      if (!!monthsSelected.find((el) => el === key)) {
        setMonthsSelected(monthsSelected.filter((e) => e !== key));
      } else {
        setMonthsSelected([...monthsSelected, key]);
      }
    }
  };

  const [pageSize, setPageSize] = useState(4);

  useEffect(() => {
    function handleResize() {
      if (window.innerHeight < 800) {
        setPageSize(4);
      } else if (window.innerHeight > 800 && window.innerHeight < 900) {
        setPageSize(6);
      } else if (window.innerHeight > 900) {
        setPageSize(8);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="meetings-content">
      <div className="frame-16">
        <div className="frame-17">
          <div className="meetings-topbar">
            <div className="meetings-topbar-title-iconplace">
              <TwentyIcHandServices
                className="meetings-topbar-title-iconplace-icon"
                color="#36B39E"
              />
            </div>
            <div className="meetings-topbar-title">Resoconto appuntamenti</div>
          </div>
        </div>
      </div>
      <div className="meetings-topbar-commands-container">
        <div className="wellness-switchbar-tabs">
          <BaseTab
            select={elementSelected === 0}
            text="Elenco appuntamenti"
            onClick={() => {
              if (elementSelected !== 0) {
                setElementSelected(0);
                navigate("");
              }
            }}
          ></BaseTab>
          <BaseTab
            select={elementSelected === 1}
            text="Report"
            onClick={() => {
              if (elementSelected !== 1) {
                setElementSelected(1);
                navigate("");
              }
            }}
          ></BaseTab>
        </div>
        {!elementSelected && (
          <div className="smartplan-filterBar-search">
            {meetingsSelected.length > 0 && (
              <div
                className="meetings-button-confirm-all-invoiced"
                onClick={() => {
                  setInvoiceModalOpened(!invoiceModalOpened);
                }}
              >
                <div className="meetings-table-header-filter-text-two">
                  Conferma tutte come fatturate
                </div>
              </div>
            )}
            <SearchInput
              baseInputText={"Ricerca"}
              inputSearched={(e) => {
                setSearchKey(e);
              }}
            />
            {/* <div className="smartplan-filterBar-filterbutton">
              <BaseTab
                icon={
                  <TwentyIcFiltersOrder color="grey" className={undefined} />
                }
                isButton={true}
                text={t("smartPlan.common.filters")}
                onClick={() => setFilterModalOpened(!filterModalOpened)}
              />
            </div> */}
          </div>
        )}
      </div>
      <div>
        {elementSelected === 0 ? (
          <div>
            <div className="meetings-table-header">
              <div className="meetings-table-header-textresult">
                Lista (
                {
                  filterMeetings(
                    meetings,
                    searchKey,
                    isFilteredByInvoiced,
                    isFilteredByMeeting
                  ).length
                }
                )
              </div>
              <div>
                <Checkbox
                  disabled={isFilteredByInvoiced}
                  value={isFilteredByMeeting}
                  onClick={() => setIsFilteredByMeeting(!isFilteredByMeeting)}
                >
                  {" "}
                  Da confermare
                </Checkbox>
                <Checkbox
                  disabled={isFilteredByMeeting}
                  value={isFilteredByInvoiced}
                  onClick={() => setIsFilteredByInvoiced(!isFilteredByInvoiced)}
                >
                  {" "}
                  Solo da fatturare
                </Checkbox>
                <Dropdown
                  menu={{
                    items: dropdownMonthsList,
                    onClick: dropdownMonthsHandleChange,
                    multiple: true,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Mese:
                      <b
                        className="meeting-table-footer-section-text-two"
                        style={{ paddingRight: "5px" }}
                      >
                        {monthsSelected.length === 0
                          ? "Tutti i mesi"
                          : monthsSelected.length > 1
                          ? monthsSelected.length + " elementi"
                          : monthTranslatorFromNumber(monthsSelected[0])}
                      </b>
                    </Space>
                  </a>
                </Dropdown>
                <Dropdown
                  menu={{
                    items: dropdownOrderList,
                    onClick: onOrderListClick,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Ordina per:
                      <b className="meeting-table-footer-section-text-two">
                        {sortOrder}
                      </b>
                    </Space>
                  </a>
                </Dropdown>
              </div>
            </div>
            <Table
              rowKey="id"
              loading={meetingsSliceStatus === "loading" ? true : false}
              dataSource={filterMeetings(
                meetings,
                searchKey,
                isFilteredByInvoiced,
                isFilteredByMeeting
              )}
              columns={columns}
              pagination={{ pageSize: pageSize }}
              rowSelection={rowSelection}
              footer={() => {
                const filteredMeetings = filterMeetings(
                  meetings,
                  searchKey,
                  isFilteredByInvoiced,
                  isFilteredByMeeting
                );

                const isError = filteredMeetings.includes(
                  "Request failed with status code 500"
                );

                const totalServices = isError
                  ? 0
                  : Array.isArray(filteredMeetings)
                  ? filteredMeetings.length
                  : 0; 

                console.log("Filtered Meetings:", filteredMeetings); 
                console.log("Total Services:", totalServices); 

                return (
                  <>
                    <div className="meeting-table-footer-container">
                      <div
                        className="meeting-table-footer-section-container"
                        style={{ textAlign: "left" }}
                      >
                        <div className="meeting-table-footer-section-text-one">
                          TOTALE SERVIZI
                        </div>
                        <div className="meeting-table-footer-section-text-two">
                          {
                            totalServices > 0
                              ? totalServices +
                                " " +
                                (totalServices === 1 ? "servizio" : "servizi")
                              : "" 
                          }
                        </div>
                        <div className="meeting-table-footer-section-text-one">
                          Completati:{" "}
                          {
                            meetings
                              .filter(
                                (meeting) => meeting.status === "COMPLETED"
                              ).length
                          }
                        </div>
                        <div className="meeting-table-footer-section-text-one">
                          In attesa:{" "}
                          {
                            meetings
                              .filter((meeting) => meeting.status === "PENDING")
                              .length
                          }
                        </div>
                      </div>
                      <div
                        className="meeting-table-footer-section-container"
                        style={{ textAlign: "right" }}
                      >
                        <div className="meeting-table-footer-section-text-one">
                          TOTALE COMPLESSIVO
                        </div>
                        <div className="meeting-table-footer-section-text-two">
                          {totalInvoice() > 0 ? totalInvoice() + ",00 €" : ""}
                        </div>
                        <div className="meeting-table-footer-section-text-one">
                          Fatturato:{" "}
                          {(() => {
                            const total = meetings
                              .filter(
                                (meeting) =>
                                  meeting.status === "INVOICED" ||
                                  meeting.status === "PURCHASED"
                              )
                              .reduce(
                                (total, meeting) =>
                                  total +
                                  (meeting.invoicePrice !== null
                                    ? meeting.invoicePrice
                                    : 0), 
                                0
                              );

                            if (isNaN(total) || total === 0) {
                              return "0,00 €"; 
                            }

                            return total.toLocaleString("it-IT", {
                              style: "currency",
                              currency: "EUR",
                            });
                          })()}
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            ></Table>
          </div>
        ) : (
          <div>
            {dataReportForCurrentYear && (
              <Card>
                <div className="meetings-report-first-row-card">
                  <div className="meetings-report-title-card">
                    Totale compensi
                  </div>
                  <div className="meetings-report-profit-text">
                    <div className="meetings-report-line-chart"></div>
                    <div className="meetings-report-profit-text-font">
                      Guadagno 2024
                    </div>
                  </div>
                </div>
                <div className="meetings-report-second-row-card">
                  <div>
                    <div className="meetings-report-profit-title">
                      GUADAGNO TOTALE 2024
                    </div>
                    <div className="meetings-report-profit">
                      {formatNumber(dataReportForCurrentYear?.totalProfit) +
                        "€"}
                    </div>
                  </div>
                  <div className="line-vertical"></div>
                  <div>
                    <div className="meetings-report-profit-title">
                      DA RISCUOTERE
                    </div>
                    <div className="meetings-report-not-profit">
                      {formatNumber(dataReportForCurrentYear?.toBeCollected) +
                        "€"}
                    </div>
                    <div className="meetings-report-last-profit-title">
                      <div className="meetings-report-last-profit-text">
                        Ultimo incasso:&nbsp;
                      </div>
                      <div className="meetings-report-last-profit-result">
                        {formattedData(
                          dataReportForCurrentYear?.lastCollectionDate
                        ) +
                          " " +
                          "·" +
                          " " +
                          formatNumber(
                            dataReportForCurrentYear?.lastCollectionPrice
                          ) +
                          "€"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-chart-report-meetings">
                  <div className="chart-report">
                    <ReactApexChart
                      width="100%"
                      height="100%"
                      options={options}
                      series={series}
                      type="bar"
                    />
                  </div>
                </div>

                <div className="meetings-report-second-row-card">
                  <div>
                    <div className="meetings-report-profit-title">
                      TOTALE FATTURATO
                    </div>
                    <div className="meetings-report-total-profit">
                      {formatNumber(dataReportForCurrentYear?.totalInvoices) +
                        "€"}
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
        )}
      </div>
      {/*<ModalWrapper
        open={filterModalOpened}
        closeAction={() => setFilterModalOpened(!filterModalOpened)}
      >
        <ModalBody title={"Filtri"}>
          <div className="meetings-filtermodal">
            <div className="meetings-filtermodal-inputs-container">
              <div className="meeting-filtermodal-input-container">
                <div className="meeting-filtermodal-text">Nome utente</div>
                <Input
                  style={{ height: "40px", width: "230px" }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="meeting-filtermodal-input-container">
                <div className="meeting-filtermodal-text">Cognome utente</div>
                <Input
                  style={{ height: "40px", width: "230px" }}
                  value={lastName}
                  onChange={(e) => setLastname(e.target.value)}
                />
              </div>
            </div>
            <div className="meeting-filtermodal-input-container">
              <div className="meeting-filtermodal-text">Servizio</div>
              <Dropdown
                menu={{ items: servicesList, onClick: onServiceClick }}
                disabled={servicesByProvider.length === 0}
              >
                <Button style={{ height: "40px" }}>
                  <Space>{servicesSelected}</Space>
                </Button>
              </Dropdown>
            </div>
            <div className="meeting-filtermodal-input-container">
              <div className="meeting-filtermodal-text">Mesi</div>
              <Select
                suffixIcon={
                  monthsSelected.length === 0 && (
                    <TwentyIcArrowDown className={undefined} color="#36b39e" />
                  )
                }
                value={monthsSelected}
                tagRender={tagRender}
                placeholder={t("smartPlan.filterModal.select")}
                bordered={false}
                mode="multiple"
                allowClear
                style={{
                  paddingTop: "2px",
                  paddingBottom: "4px",
                  gap: "5px",
                  alignItems: "center",
                  width: "100%",
                  minHeight: "40px",
                  height: "100%",
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                }}
                onChange={monthsHandleChange}
                options={selectMonthsList}
              />
            </div>
            <div className="meeting-filtermodal-input-container">
              <div className="meeting-filtermodal-text">
                Conferma dei servizi da parte degli utenti
              </div>
              <div className="meetings-switchbar-tabs">
                <BaseTab
                  select={acceptedByUser === "ALL"}
                  text="Tutto"
                  onClick={() => {
                    setAcceptedByUser("ALL");
                  }}
                />
                <BaseTab
                  select={acceptedByUser === false}
                  text="Da confermare"
                  onClick={() => {
                    setAcceptedByUser(false);
                  }}
                />
                <BaseTab
                  select={acceptedByUser === true}
                  text="Confermati"
                  onClick={() => {
                    setAcceptedByUser(true);
                  }}
                />
              </div>
            </div>
            <div className="meeting-filtermodal-input-container">
              <div className="meeting-filtermodal-text">Stato fattura</div>
              <div className="meetings-switchbar-tabs">
                <BaseTab
                  select={invoiced === "ALL"}
                  text="Tutto"
                  onClick={() => {
                    setInvoiced("ALL");
                  }}
                />
                <BaseTab
                  select={invoiced === true}
                  text="Fatturato"
                  onClick={() => {
                    setInvoiced(true);
                  }}
                />
                <BaseTab
                  select={invoiced === false}
                  text="Da fatturare"
                  onClick={() => {
                    setInvoiced(false);
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          primaryLabel={"Aggiorna filtri"}
          secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
          primaryAction={() => {
            meetingSearchParamBuilderAndCallAsync();
            setFilterModalOpened(!filterModalOpened);
          }}
          secondaryAction={() => clearFilters()}
          disablePrimaryButton={false}
        />
        </ModalWrapper>*/}
      <div className="modal-status-meeting">
        <ModalWrapper
          open={servicesModalOpened}
          closeAction={() => setServicesModalOpened(!servicesModalOpened)}
        >
          <ModalBody title={"Aggiornamento stato appuntamento"}>
            <br />
            <div className="meetings-statusmodal-text">
              Stai aggiornando lo stato del tuo appuntamento. Sarà inviata in
              automatico una comunicazione all'utente:
            </div>
            <br />
            <div className="meetings-statusmodal-preview-container">
              <div className="meetings-statusmodal-preview-text-container">
                <div className="meetings-table-header-filter-text-two">
                  Utente
                </div>
                <div className="meetings-table-header-filter-text-one">
                  {meetingsSelected[0]?.firstName
                    ? meetingsSelected[0]?.firstName +
                      " " +
                      meetingsSelected[0]?.lastName
                    : ""}
                </div>
              </div>
              <div className="meetings-statusmodal-preview-text-container">
                <div className="meetings-table-header-filter-text-two">
                  Data invio richiesta
                </div>
                <div className="meetings-table-header-filter-text-one">
                  {formattedData(new Date())}
                </div>
              </div>
              <div className="meetings-statusmodal-preview-text-container"></div>
            </div>
            <br />
            <div className="divider" />
            <br />
            <div className="meetings-table-header-filter-text-two">
              Seleziona lo stato del tuo appuntamento
            </div>
            <br />
            <div className="meetings-statusmodal-checkbos-section">
              <Radio
                checked={meetingExecuted}
                onClick={() => setMeetingExecuted(!meetingExecuted)}
              >
                <div className="meetings-table-header-filter-text-one">
                  Eseguito
                </div>
              </Radio>
              <Radio
                checked={!meetingExecuted}
                onClick={() => setMeetingExecuted(!meetingExecuted)}
              >
                <div className="meetings-table-header-filter-text-one">
                  Non eseguito
                </div>
              </Radio>
            </div>
            <br />
            {!meetingExecuted && (
              <>
                <div className="meetings-table-header-filter-text-two">
                  Motivazione della seduta non eseguita
                </div>
                <Dropdown menu={{ items: reasons, onClick: onReasonClick }}>
                  <Button style={{ height: "40px", width: "100%" }}>
                    <Space>
                      <div className="meetings-table-header-filter-text-one">
                        {reasonSelected === ""
                          ? "Scegli una motivazione"
                          : reasonSelected}
                      </div>
                    </Space>
                  </Button>
                </Dropdown>
                <br />
                <br />
              </>
            )}

            {reasonSelected === "Altro" && !meetingExecuted && (
              <>
                <div className="meetings-table-header-filter-text-two">
                  Lascia un commento
                </div>
                <Input.TextArea
                  id="annotations-text-area"
                  defaultValue={annotiationsMeetingStatus}
                  value={annotiationsMeetingStatus}
                  style={{ width: "100%", height: "100px" }}
                  size="large"
                  placeholder="Motivazione della seduta non eseguita..."
                  onChange={(e) => setAnnotationsMeetingStatus(e.target.value)}
                />
              </>
            )}
          </ModalBody>
          <ModalFooter
            primaryLabel={"Aggiorna"}
            secondaryLabel={t("account.modalChangePassowrd.buttonCancel")}
            primaryAction={() => {
              meetingStatusParamsBuilderAndCallAsync();
              setServicesModalOpened(!servicesModalOpened);
            }}
            secondaryAction={() => {
              setServicesModalOpened(!servicesModalOpened);
              clearMeetingModalFilters();
              setMeetingsSelected([]);
            }}
            disablePrimaryButton={false}
          />
        </ModalWrapper>
      </div>
      {invoiceModalOpened && (
        <div className="modal-invoice-meeting-backdrop">
          <div className="modal-invoice-meeting">
            <div className="delete-service-modal-icon-info-container">
              <IconInfo className={undefined} />
            </div>
            <div className="modal-invoice-meeting-text-one">
              Stai segnando{" "}
              {meetingsSelected.length === 1
                ? "questo servizio"
                : meetingsSelected.length + " servizi"}{" "}
              come fatturato. Questa operazione non è reversibile. Sei sicuro di
              voler procedere?
            </div>
            <div className="meetings-invoice-modal-buttons-container">
              <Button
                className="meetings-invoice-button-dimiss"
                onClick={() => {
                  setInvoiceModalOpened(!invoiceModalOpened);
                  setMeetingsSelected([]);
                }}
              >
                <div className="meetings-invoice-button-dimiss-text">
                  {t("provider.cancelDelete")}
                </div>
              </Button>
              <Button
                className="meetings-invoice-button-delete"
                onClick={() => {
                  meetingInvoiceStatusParamsBuilderAndCallAsync();
                  setInvoiceModalOpened(!invoiceModalOpened);
                }}
              >
                <div className="meetings-invoice-button-cancel-text">
                  <IconTik className={undefined} color="#ffffff" />
                  Procedi
                </div>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
