import React from "react";
import "./MasterClassFilterBar.css";
import { BaseTab } from "../BaseTab/BaseTab";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

interface MasterClassFilterBarProps {
  setSwitchSelected: (value: number) => void;
  switchStatus: number;
}

export const MasterClassFilterBar: React.FC<MasterClassFilterBarProps> = ({
  setSwitchSelected,
  switchStatus,
}) => {
  const navigate = useNavigate();

  return (
    <div className="masterclass-filterbar">
      {/* BUTTON ZONE */}
      <div className="masterclass-filterBar-tabs">
        <BaseTab
          select={switchStatus === 0}
          text={t("masterClass.components.filterBar.allMasterclass")}
          onClick={() => {
            setSwitchSelected(0);
            navigate("/masterclass");
          }}
        />
        <BaseTab
          select={switchStatus === 1}
          text={"Richieste in corso"}
          onClick={() => {
            setSwitchSelected(1);
            navigate("/masterclass/request/myOrders");
          }}
        />
        <BaseTab
          select={switchStatus === 2}
          text={t("masterClass.components.filterBar.mine")}
          onClick={() => {
            setSwitchSelected(2);
            navigate("/masterclass/myOrders");
          }}
        />
      </div>
    </div>
  );
};
