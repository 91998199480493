import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import HeaderMenu from "../../layout/HeaderMenu";
import { Footer } from "../../screens/Footer/Footer";
import { AssistanceBoxComponent } from "../../ui/components/AssistanceBoxComponent";
import AssignedPeople from "../../ui/components/MyOrders/AssignedPeople";
import OrderDetailCard from "../../ui/components/MyOrders/OrderDetailCard";
import { OrderSummary } from "../../ui/components/MyOrders/OrderSummary";
import { TopBar } from "../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../ui/components/icons/TwentyIcArrowLineLeft";
import {
  TeamUserInfo,
  getFilteredUsersInfoPaginationAsync,
  teamUserInfoSelectors,
} from "../MyTeam/TeamUsersInfoSlice";
import { MasterClassDetail } from "../masterclass/MasterClassSlice";
import {
  MyOrderDetail,
  getOrderAsync,
  myOrderDetailSelector,
} from "./MyOrderDetailSlice";
import "./OrderDetail.css";

export interface MasterClassOrderDetailProps {}

const MasterClassOrderDetail: React.FC<MasterClassOrderDetailProps> = () => {
  const navigate = useNavigate();

  const [id, setId] = useState<number>(-1);
  const [masterClass, setMasterClass] = useState<MasterClassDetail>();
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");
  const isAdmin = localStorage.getItem("admin");

  const orderRoute = useMatch("masterClass/myOrders/:id");

  const teamUsersInfo: TeamUserInfo[] = useAppSelector(
    teamUserInfoSelectors.selectAll
  );

  let order: MyOrderDetail = useAppSelector(
    (state) =>
      myOrderDetailSelector.selectById(state, id) ?? ({} as MyOrderDetail)
  );

  useEffect(() => {
    if (masterClass) {
      let startDay = new Date(masterClass.startDate);
      let endDay = new Date(masterClass.endDate);
      let startDate = startDay.toLocaleDateString("it-IT", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
      let endDate = endDay.toLocaleDateString("it-IT", {
        weekday: "long",
        month: "short",
        day: "numeric",
      });
      setStartDate(startDate);
      setEndDate(endDate);
    }
  }, [masterClass]);

  useEffect(() => {
    if (orderRoute !== null) {
      if (orderRoute.params.id) {
        setId(parseInt(orderRoute.params.id));
        store.dispatch(getOrderAsync(parseInt(orderRoute.params.id)));
        store.dispatch(getFilteredUsersInfoPaginationAsync());
        return;
      }
    }
  }, [orderRoute]);

  useEffect(() => {
    if (order.masterClass) {
      setMasterClass(order.masterClass);
    }
  }, [order]);

  return (
    <>
      {masterClass && (
        <>
          <HeaderMenu>
            <TopBar
              title={masterClass.title}
              hasEdit={false}
              hasDate={false}
              breadcrumbText={"MasterClass > Dettaglio ordine"}
              icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
              onClick={() => navigate("/masterclass/myOrders")}
            />
          </HeaderMenu>
          <div className="order-detail">
            <div className="order-detail-left-side">
              <OrderDetailCard
                id={order.id}
                date={order.bookingDate}
                price={masterClass.price}
                image={masterClass.image}
                status={order.status}
                personsNumber={order.numberOfPeople}
              />
              <AssignedPeople
                bookingId={order.id}
                currentUsers={order.userInfo}
                users={teamUsersInfo}
                numberOfPeople={order.numberOfPeople}
              />
            </div>
            <div className="order-detail-right-side">
              <OrderSummary
                departure={startDate}
                comeBack={endDate}
                price={masterClass.price}
                numberOfPeople={order.numberOfPeople}
                bookingStatus={order.status}
              ></OrderSummary>
              <div className="summary-column">
                <AssistanceBoxComponent
                  assistance={t(
                    "smartPlanDetail.componentAssistantBox.assistance"
                  )}
                  whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                  date={t("smartPlanDetail.componentAssistantBox.date")}
                  whatsAppNumber={3773604135}
                  whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                    .replace("firstName*", firstName ?? "")
                    .replace("lastName*", lastName ?? "")
                    .replace("companyName*", businessName ?? "")}
                  disabled={undefined}
                />
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default MasterClassOrderDetail;
